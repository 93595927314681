import React, { Fragment, Component } from 'react'
import Helmet from 'react-helmet'
import { calcImageSrcUrl } from 'ion-image'

export class Klangoo extends Component {
  componentWillMount () {
    if (typeof window !== 'undefined' && typeof __magnetLaunch === 'function') {
      __magnetLaunch() // eslint-disable-line no-undef
    }
  }

  render () {
    const resizeURL = process.env.RAZZLE_RESIZE_URL
    return (<Fragment>
      <Helmet>
        <script src='//magnetapi.klangoo.com/w/Widgets_319.js' type='text/javascript' async />
        <meta itemProp='headline' content={this.props.article.headline} />
        <meta itemProp='description' name='description' content={this.props.article.abstract} />
        <meta itemprop='image' content={resizeURL + calcImageSrcUrl(this.props.article.getImageObject(), 960, 'original')} />
        <meta itemprop='thumbnailUrl' content={resizeURL + calcImageSrcUrl(this.props.article.getImageObject(), 100, 'original')} />
        <meta itemProp='inLanguage' content='en' />
        <meta itemprop='identifier' content={this.props.article.contentKey} />
        <meta itemprop='pageType' content='article' />
        <meta itemprop='datePublished' content={this.props.article.published} />
        <meta itemprop='dateModified' content={this.props.article.modified} />
        <meta itemprop='author' content={this.props.article.getAuthor()} />
      </Helmet>
      <div className='related-articles'>
        <div data-widget-id={this.props.widgetId} />
      </div>
    </Fragment>)
  }
}

export default Klangoo
