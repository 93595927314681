import React, { Fragment } from 'react'
import { Route, Switch } from 'react-router-dom'
import { Redirect } from 'react-router'
import Helmet from 'react-helmet'
import { getSectionLabel, url2section } from 'ion-sections'

import DefaultSectionPage from '../pages/DefaultSectionPage'
import AuthorSectionPage from '../pages/AuthorSectionPage'
import AuthorArticles from '../templates/Articles/AuthorArticles'
import Article from '../pages/Article'
import NotFound from '../pages/NotFound'
import qs from 'qs'

const DefaultSection = (props) => {
  const params = props.location ? qs.parse(props.location.search, { ignoreQueryPrefix: true }) : { page: 0 }
  const isPage0 = (params.page || 0) < 2
  return (
    <Fragment>
      <Helmet titleTemplate={props.titleTemplate} />
      <Switch>
        <Route path={props.match.url + '/:section([a-zA-Z0-9-]+)/'} strict exact render={p => <Redirect to={props.match.url + '/' + p.match.params.section} />} />
        {props.match.url === props.location.pathname && (
          <Switch>
            <Route path='/business' exact render={(p) =>
              <DefaultSectionPage {...props} {...p} isPage0={isPage0}
                section='business'
                title='Trending Business and Finance News Updates | African Independent'
                description='African Independent highlights some of Africa’s most promising startups and established businesses, and profiles some of the continent’s most successful entrepreneurs.'
              />} />
            <Route path='/technology' exact render={(p) =>
              <DefaultSectionPage {...props} {...p} isPage0={isPage0}
                section='technology'
                sectionLabel='tech'
                title='Trending Technology Updates | African Independent'
                description='African Independent highlights some of Africa’s most promising startups and established businesses, and profiles some of the continent’s most successful entrepreneurs.'
              />} />
            <Route path='/opinion' exact render={(p) =>
              <DefaultSectionPage {...props} {...p} isPage0={isPage0}
                section='opinion'
                sectionLabel='Leading Voice'
                title='Opinion | Leading Voices | African Independent'
                description='Africa’s most prominent business leaders, entrepreneurs, and decision-makers share their insights and advice on an array of interesting topics on African Independent.'
              />} />
            <Route path='/culture' exact render={(p) =>
              <DefaultSectionPage {...props} {...p} isPage0={isPage0}
                section='culture'
                sectionLabel='Lifestyle & Culture'
                title='Trending Lifestyle & Culture Articles | African Independent'
                description='Explore uniquely African culture, art, food, festivals and fashion, and read profiles on the artists, chefs, organisers and designers behind them on African Independent.'
              />} />
            <Route path='/environment' exact render={(p) =>
              <DefaultSectionPage {...props} {...p} isPage0={isPage0}
                section='environment'
                title='Latest Environment & Sustainability News | African Independent'
                description='We cover the latest news, trends and developments around sustainability and eco-mindfulness on the African continent, with profiles on industry leaders and businesses.'
              />} />
            <Route path='/sport' exact render={(p) =>
              <DefaultSectionPage {...props} {...p} isPage0={isPage0}
                section='sport'
                title='SA and International Sports News and Comment | African Independent'
                description='African Independent profiles Africa’s leading sportspeople, from MMA fighters to rugby anchors, keeping you up-to-date with the latest sports news and celebrity content.'
              />} />
            <Route path='/news' exact render={(p) =>
              <DefaultSectionPage {...props} {...p} isPage0={isPage0}
                section='news'
                title='Latest Africa News Updates | African Independent'
                description='Get the latest and trending top political, business, finance news and featured stories from the African continent on African Independent.'
              />} />
            <Route path='/east-africa' exact render={(p) =>
              <DefaultSectionPage {...props} {...p} isPage0={isPage0}
                section='east-africa'
                title='Latest News From East Africa | African Independent'
                description='Get the latest and trending top political, business, finance news and featured stories from East Africa on African Independent.'
              />} />
            <Route path='/west-africa' exact render={(p) =>
              <DefaultSectionPage {...props} {...p} isPage0={isPage0}
                section='west-africa'
                title='Latest News From West Africa | African Independent'
                description='Get the latest and trending top political, business, finance news and featured stories from West Africa on African Independent.'
              />} />
            <Route path='/southern-africa' exact render={(p) =>
              <DefaultSectionPage {...props} {...p} isPage0={isPage0}
                section='southern-africa'
                title='Latest News From Southern Africa | African Independent'
                description='Get the latest and trending top political, business, finance news and featured stories from Southern Africa on African Independent.'
              />} />
            <Route path='/travel' exact render={(p) =>
              <DefaultSectionPage {...props} {...p} isPage0={isPage0}
                section='travel'
                title='Latest Travel News, Trends and Information | African Independent'
                description='Come on the journey with us as we explore some of Africa’s most exciting and luxurious travel destinations. African Independent Travel gives you the best in travel news.'
              />} />
            <Route path='/anc-conference' exact render={(p) =>
              <DefaultSectionPage {...props} {...p} isPage0={isPage0}
                section='anc-conference'
                title='Latest ANC Conference Updates | African Independent'
                description='Get the latest news about elected and re-elected members to the ANC National Executive Committee at the ANC Conference, held every five years, on African Independent.'
              />} />
            <Route path='/wef-africa' exact render={(p) =>
              <DefaultSectionPage {...props} {...p} isPage0={isPage0}
                section='wef-africa'
                title='Trending Word Economic Forum Updates | African Independent'
                description='African Independent gives you the latest news and insights on the World Economic Forum. Find out which topics are on the agenda for leaders in business and politics.'
              />} />
            <Route path='/test' exact render={(p) =>
              <Fragment>
                <Helmet>
                  <meta name='robots' content='noindex, nofollow' />
                </Helmet>
                <DefaultSectionPage {...props} {...p} isPage0={isPage0}
                  section='all-content-feed'
                  title='Test page | African Independent'
                  description='Test page for marketing purposes'
                />
              </Fragment>} />
            <Route path='/:section([a-zA-Z/-]+)' exact render={(p) =>
              <DefaultSectionPage {...props} {...p} isPage0={isPage0}
                title={getSectionLabel(url2section(props.location.pathname)) + ' | African Independent'}
                section={url2section(props.location.pathname)} url={props.location.pathname}
              />} />
            <Route path='*' render={(p) => <NotFound {...props} {...p} />} />
          </Switch>
        )}
        <Route path='/authors/:section([a-zA-Z-]+)' render={(p) => <AuthorSectionPage {...props} {...p}
          template={AuthorArticles}
          title={getSectionLabel(url2section(props.location.pathname)) + ' | African Independent'}
          section={url2section(props.location.pathname)}
          url={props.location.pathname}
        />} />
        <Route path={props.match.url + '/:title*([-])*:contentKey([0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12})'} render={(p) => <Article {...props} {...p} contentKey={p.match.params.contentKey} />} />
        <Route path={props.match.url + '/:title*([-])*:contentKey([0-9]{5,})'} render={(p) => <Article {...props} {...p} contentKey={p.match.params.contentKey} />} />
        <Route path={props.match.url + '/:section([a-zA-Z-]+)'} render={(p) => <DefaultSection {...props} {...p} />} />
      </Switch>
    </Fragment>
  )
}

export default DefaultSection
