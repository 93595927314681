import React, { Fragment } from 'react'
import Helmet from 'react-helmet'
import { getSectionLabel } from 'ion-sections'
import { Articles, SectionMeta } from 'ion-article-cmp'
import WingBanners from '../components/WingBanners'
import Ad from 'ion-ads'
import FORMATS from '../components/AdFormats'
import Sidebar from '../components/Sidebar'
import logo from '../static/general/african-independent.svg'
import NoImage from '../static/no-image.png'
import NotFound from './NotFound'

const AuthorSectionPage = (props) => {
  return (
    <Fragment>
      <Helmet titleTemplate={'%s'}
        title={props.title || getSectionLabel(props.section)} >
        <meta property='fb:app_id' content='293175074032541' />
        <meta property='og:type' content='section' />
        <meta property='og:title' content={props.title || getSectionLabel(props.section)} />
        <meta property='og:image' content={logo} />
        <meta itemProp='headline' content={props.title || 'Latest News from ' + getSectionLabel(props.section)} />
        <meta name='description' content={props.description} />
        <meta itemProp='description' content={props.description} />
        <meta property='og:description' content={props.description} />
        <meta property='og:url' content={props.url} />
        <meta property='keywords' content={props.keywords} />
        <meta name='twitter:site' content={props.twitterName} />
        <meta name='twitter:creator' content={props.twitterName} />
        <meta name='twitter:title' content={props.title || getSectionLabel(props.section)} />
        <meta name='twitter:description' content={props.description} />
        <meta itemprop='image' content={logo} />
        <meta itemprop='thumbnailUrl' content={logo} />
        <meta name='twitter:card' content='summary_large_image' />
        <meta name='twitter:image:src' content={logo} />
      </Helmet>
      <WingBanners useSmall={props.useSmall} isConnected={props.isConnected} location={props.location} onSlotRenderEndedLeft={props.onSlotRenderEndedLeft} onSlotRenderEndedRight={props.onSlotRenderEndedRight} />
      <Ad isConnected={props.isConnected} path={`/${process.env.RAZZLE_DFP_CODE}/${props.section}`} slotId='leaderboard-top' targeting={{ leaderboard: 'top' }} collapseEmptyDiv className='advert-leaderboard-top' {...FORMATS.leaderboard} />
      <div className='wrapper article-listing'>
        <Articles {...props} component={props.template} section={props.section} count={24} pageSize={24} noImage={NoImage} notFound={NotFound} withMore />
        <Sidebar section={props.section} />
      </div>
      <Ad isConnected={props.isConnected} path={`/${process.env.RAZZLE_DFP_CODE}/${props.section}`} slotId='leaderboard-bot' targeting={{ leaderboard: 'bot' }} collapseEmptyDiv className='advert-leaderboard-top' {...FORMATS.leaderboard} />
      <SectionMeta {...props} />
    </Fragment>
  )
}

AuthorSectionPage.defaultProps = {
  component: 'AuthorArticles',
  section: 'author'
}

export default AuthorSectionPage
