import React, { Component, Fragment } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Redirect } from 'react-router'
import { Link } from 'react-router-dom'
import ReactDom from 'react-dom'
import Helmet from 'react-helmet'
import { Dimmer, Loader, Segment } from 'semantic-ui-react'
import 'semantic-ui-css/components/dimmer.min.css'
import 'semantic-ui-css/components/loader.min.css'
import 'semantic-ui-css/components/segment.min.css'

import { fetchArticle, setPreviewArticle } from '../../store/article'
import IonArticle from 'ion-article'
import { calcImageSrcUrl, ImageCalculator } from 'ion-image'
import { articleUrl2section } from 'ion-sections'
import { ArticleFeaturedImage, ArticleMeta } from 'ion-article-cmp'
import Ad from 'ion-ads'
import FORMATS from '../components/AdFormats'
import RelatedArticles from '../components/RelatedArticles'
import { Mobile, Tablet, Desktop, defaultWidth } from 'ion-media'
import ArticleSocialShare from '../components/ArticleSocialShare'
import NotFound from './NotFound'
import unknownImage from '../static/no-image.png'
import OfflineImage from '../static/icons/broken-connection.svg'
import Sidebar from '../components/Sidebar'
import Klangoo from '../components/Klangoo'

// Pulled from siteProps
export class Article extends Component {
  constructor (props) {
    super(props)
    this.scriptloaded = this.scriptloaded.bind(this)
    if (this.props.article && (this.props.previewArticle || this.props.article.contentKey === this.props.contentKey)) {
      this.props.serverArticleRender(this.props.article)
    }
  }

  componentDidUpdate (prevProps) {
    // Re-fetch articles when the contentKey changes
    // eslint-disable-next-line eqeqeq
    if (prevProps.contentKey != this.props.contentKey) {
      this.props.fetchArticle(this.props.contentKey)
    }
    if (this.props.article) {
      this.processArticle()
    }
  }

  componentWillMount () {
    // Fetch the first time this component is mounted
    if (this.props.ArticlePushContext) {
      this.props.setPreviewArticle(this.props.ArticlePushContext)
    } else {
      const now = new Date()
      const lastFetch = new Date(this.props.lastFetch ? this.props.lastFetch : 0)
      // eslint-disable-next-line eqeqeq
      if (!this.props.article || this.props.contentKey != this.props.article.contentKey || now - lastFetch > (60 * 1000)) { // 60 seconds time
        this.props.fetchArticle(this.props.contentKey)
      }
    }
  }

  scriptloaded () {
    this.scripts--
    console.log('Script loaded', this.scripts, 'left to load')
    if (this.scripts === 0) {
      console.log('All scripts loaded')
      this.nodeScriptReplace(document.getElementById('article-more-body'), false)
    }
  }

  nodeScriptIs (node) {
    return node.tagName === 'SCRIPT'
  }

  nodeScriptClone (node, src, callback) {
    if (!src && node.src) {
      return node
    }
    if (src && !node.src) {
      return node
    }
    var script = document.createElement('script')
    script.text = node.innerHTML
    for (var i = node.attributes.length - 1; i >= 0; i--) {
      script.setAttribute(node.attributes[i].name, node.attributes[i].value)
    }
    if (callback) {
      return callback(script)
    }
    return script
  }

  nodeScriptReplace (node, src, callback) {
    if (!node) { return }
    if (this.nodeScriptIs(node) === true) {
      node.parentNode.replaceChild(this.nodeScriptClone(node, src, callback), node)
    } else {
      var i = 0
      var children = node.childNodes
      while (i < children.length) {
        this.nodeScriptReplace(children[i++], src, callback)
      }
    }
  }

  renderRelatedArticles () {
    if (this.props.article.relatedArticles.length > 0) {
      let articleNode = document.getElementById('article-more-body')
      let relatedArticleBlock = document.createElement('div')
      relatedArticleBlock.id = 'relatedArticle'
      relatedArticleBlock.className = 'related-articles'
      let node = articleNode.querySelector(`p:nth-child(4)`)
      if (node) {
        node.parentElement.insertBefore(relatedArticleBlock, node)
      }
      return relatedArticleBlock
    } else {
      return false
    }
  }

  renderAuthors (article) {
    if (article.authors) {
      return article.authors.map((author, index, arr) => {
        if (arr.length - 1 === index) {
          // last one
          return <strong key={index}>{author.hasAuthorPage ? <Link to={'/' + author.slug}>  {author.name} </Link> : author.name}</strong>
        } else {
          return <strong key={index}>{author.hasAuthorPage ? <Link to={'/' + author.slug}>  {author.name}, </Link> : author.name + ', '}</strong>
        }
      })
    }
  }

  processArticle () {
    let articleNode = document.getElementById('article-more-body')
    if (articleNode) {
      this.scripts = 0
      this.nodeScriptReplace(articleNode, true, (node) => {
        this.scripts++
        node.async = false
        node.onload = this.scriptLoaded
        return node
      })
    }
    let ad = document.getElementById('inarticlead')
    let relatedArticleBlock = this.renderRelatedArticles()
    if (ad) {
      const article = new IonArticle(this.props.article)
      ReactDom.render(<Ad isConnected={this.props.isConnected} path={`/${process.env.RAZZLE_DFP_CODE}/${article.getCanonicalUri()}`} slotId='inarticle-middle' targeting={{ inarticle: 'middle' }} collapseEmptyDiv className='advert-leaderboard' {...FORMATS.inarticle} />, ad)
    }
    if (relatedArticleBlock !== false) {
      ReactDom.render(<RelatedArticles relatedArticles={this.props.article.relatedArticles} unknownImage={unknownImage} />, relatedArticleBlock)
    }
  }

  resizeImages (width, bodyHTML) {
    let images = bodyHTML.match(/<img [^>]+>/g)
    if (images) {
      const calc = new ImageCalculator()
      const shape = 'original'
      const resizeURL = process.env.RAZZLE_RESIZE_URL

      for (var i = 0; i < images.length; i++) {
        if (!images[i].match(/srcset=/)) {
          const image = JSON.parse(images[i]
            .replace(/=/g, ':')
            .replace(/['"][ ]+/g, '",')
            .replace(/([ ,])([a-z0-9-]+):/gi, '$1"$2":')
            .replace(/<img\s+/, '{')
            .replace(/[,/ ]*>$/, '}')
          )

          let imgOffsetX = parseInt(image.offsetx) || 0
          let imgOffsetY = parseInt(image.offsety) || 0
          let imgCropWidth = parseInt(image.cropwidth) || 0
          let imgCropHeight = parseInt(image.cropwidth) || 0
          const imageWidth = parseInt(image.imageWidth)
          const imageHeight = parseInt(image.imageHeight)
          if ('imageCrop' in image) {
            const crops = image.imageCrop.split('/')
            imgOffsetX = Math.round((parseFloat(crops[0]) || 0) * imageWidth)
            imgOffsetY = Math.round((parseFloat(crops[1]) || 0) * imageHeight)
            imgCropWidth = Math.round((parseFloat(crops[2]) || 1) * imageWidth)
            imgCropHeight = Math.round((parseFloat(crops[3]) || 1) * imageHeight)
          }
          const height = calc.calcHeight(shape, width) || width * 100
          const { offsetx, offsety, cropWidth, cropHeight } = calc.getCropCoordsForShape(shape,
            width, height, imageWidth, imageHeight,
            imgOffsetX, imgOffsetY, imgCropWidth, imgCropHeight,
            0, 0)
          const src = calc.buildImageUrl(resizeURL, '', width, height, image.src, offsetx, offsety, cropWidth, cropHeight)
          bodyHTML = bodyHTML.replace(images[i], `<img class="${image.class}" src="${src}" loading="lazy" width="${width}">`)
        }
      }
    }
    return bodyHTML
  }

  componentDidMount () {
    if (this.props.article) {
      this.processArticle()
    }
  }

  render () {
    // Redirect to the correct canonical
    if (this.props.checkCanonical && (this.props.canonical !== this.props.match.url)) {
      return <Redirect to={this.props.canonical} />
    }
    // Server-side render doesn't always catch the state changes, so check always
    if (typeof window === 'undefined' && this.props.canonical && (this.props.canonical !== this.props.location.pathname)) {
      return <Redirect to={this.props.canonical} />
    }
    if (this.props.hasError && !this.props.is404) {
      if (this.props.isConnected) {
        return (<div>
          <Helmet title='ERROR' />
          <div className='wrapper'>
            <div className='article-page'>
              <article role='contentinfo' aria-label='article' className='no-elated'>
                <h1>Unexpected Error</h1>
                <p>Error: {this.props.error}</p>
              </article>
            </div>
          </div>
        </div>)
      } else {
        return (<div>
          <Helmet title='Offline' />
          <div className='wrapper no-results'>
            <img src={OfflineImage} alt='You are offline' />
            <h1>You are offline</h1>
            <p>This article is not available as you are currently offline. Please re-establish your Internet connection and try again.</p>
          </div>
        </div>)
      }
    }
    if (this.props.is404 || (this.props.hasFetched && this.props.article)) {
      if (this.props.is404 || (!this.props.ArticlePushContext && this.props.article.titleKey !== process.env.RAZZLE_TITLE_KEY)) {
        return (
          <Fragment>
            <Ad isConnected={this.props.isConnected} path={`/${process.env.RAZZLE_DFP_CODE}/404`} slotId='leaderboard-top' targeting={{ leaderboard: 'top' }} collapseEmptyDiv className='advert-leaderboard-top' {...FORMATS.leaderboard} />
            <NotFound />
            <Ad isConnected={this.props.isConnected} path={`/${process.env.RAZZLE_DFP_CODE}/404`} slotId='leaderboard-bot' targeting={{ leaderboard: 'bot' }} collapseEmptyDiv className='advert-leaderboard-top' {...FORMATS.leaderboard} />
          </Fragment>
        )
      }
      const article = new IonArticle(this.props.article)
      const resizeURL = 'https://image-prod.iol.co.za/resize'
      let tagsList = []
      if (article.primaryTag) {
        tagsList.push(article.primaryTag)
      }
      if (article.secondaryTags) {
        tagsList = tagsList.concat(article.secondaryTags)
      }
      let tags = tagsList.slice(0, 3)
      article.images = article.images.slice(0, 1)
      const published = article.formatDate()
      const headline = article.headline
      const canonicalUri = article.getCanonicalUri()
      const socialImageSrcUrl = article.socialTeaserImage ? calcImageSrcUrl(article.socialTeaserImage, 960, '16x9') : undefined
      let imageWidth = 450
      if (defaultWidth > 500) {
        imageWidth = 432
      }
      if (defaultWidth > 1024) {
        imageWidth = 1200
      }
      article.bodyHTML = this.resizeImages(imageWidth, article.bodyHTML)
      article.bodyHTML = article.bodyHTML.replace('<ad-slot/>', '<div id="inarticlead" class="in-article-ad"></div>')
      article.bodyHTML = article.bodyHTML.replace('<!-- [AD_SLOT] -->', '<div id="inarticlead" class="in-article-ad"></div>')
      // Remove all remaining <ad-slot/> elements as they are messing with the DOM
      article.bodyHTML = article.bodyHTML.replace(/<ad-slot\/>/g, '')
      const readArticles = [...this.props.readArticles]
      if (readArticles && !readArticles.includes(article.contentKey)) {
        readArticles.push(article.contentKey)
      }
      const intersection = this.props.readNext.filter(x => !readArticles.includes(x.contentKey))
      const readNext = new IonArticle(intersection[0])
      return (<Fragment>
        <div className='main-article'>
          <Helmet title={headline}>
            <meta property='fb:app_id' content='293175074032541' />
            <meta property='og:type' content='article' />
            <meta property='og:title' content={article.socialTeaserHeadline || headline} />
            <meta property='og:description' content={article.socialAbstract || article.abstract} />
            <meta property='og:url' content={this.props.canonical} />
            <meta property='keywords' content={this.props.keywords || [].concat(article.primaryTag).concat(article.secondaryTags).map(tag => tag && tag.label).join(', ')} />
            <meta property='og:image' content={resizeURL + (socialImageSrcUrl || calcImageSrcUrl(article.getImageObject(), 960, 'original'))} />
            <meta name='twitter:site' content={this.props.twitterName} />
            <meta name='twitter:creator' content={this.props.twitterName} />
            <meta name='twitter:title' content={headline} />
            <meta name='twitter:description' content={article.abstract} />
            <meta name='twitter:card' content='summary_large_image' />
            <meta name='twitter:image:src' content={resizeURL + (socialImageSrcUrl || calcImageSrcUrl(article.getImageObject(), 960, 'original'))} />

            {article.canonicalURL &&
              <meta name='robots' content='nofollow' />
            }
            {article.canonicalURL &&
              <link rel='canonical' itemprop='url' href={article.canonicalURL} />
            }
          </Helmet>
          <ArticleMeta article={article} />
          <Ad isConnected={this.props.isConnected} path={`/${process.env.RAZZLE_DFP_CODE}/${canonicalUri}`} slotId='leaderboard-top' targeting={{ leaderboard: 'top' }} collapseEmptyDiv className='advert-leaderboard-top' {...FORMATS.leaderboard} />
          <div className='wrapper'>
            <div className='article-page'>
              <article role='contentinfo' aria-label='article' className={(this.props.article.relatedArticles.length ? 'no-elated' : 'no-related')}>
                <h1>{headline}</h1>
                <p className='meta'>{article.abstract}</p>
                <p className='meta'>By {this.renderAuthors(article) || article.author}&nbsp;|&nbsp;{published && <strong>{published}</strong>}&nbsp;|&nbsp;Category <strong><Link to={'/' + article.getSectionId()}>{article.sectionLabel}</Link></strong></p>
                <ArticleSocialShare className='inline-share-buttons' url={typeof (window) !== 'undefined' ? window.location.href : (this.props.canonical ? this.props.canonical : '/')} title={headline} quote={headline} media={resizeURL + calcImageSrcUrl(article.getImageObject(), 960, 'original')} />
                <div itemProp='articleBody' className='article-body'>
                  <Mobile>
                    <ArticleFeaturedImage article={article} imageWidth={400} />
                  </Mobile>
                  <Tablet>
                    <ArticleFeaturedImage article={article} imageWidth={960} />
                  </Tablet>
                  <Desktop>
                    <ArticleFeaturedImage article={article} imageWidth={1600} />
                  </Desktop>
                  <div className='articleBodyMore' id='article-more-body' dangerouslySetInnerHTML={{
                    __html: '<!-- C-ON- TEXT_CONTENT_START --><!--sse-->' + article.bodyHTML + '<!--/sse--><!-- C-ON- TEXT_CONTENT_END -->'
                  }} />
                </div>
                <div>
                  {tags.length > 0 && (
                    <h3>Related Tags</h3>
                  )}
                  {tags.map((tag, index) => (
                    <a href={'/' + tag.sectionSlug + '/' + tag.slug} key={index}>{tag.label}<span>{tags.length > index + 1 ? ', ' : ''}</span></a>
                  ))}
                </div>
                <ArticleSocialShare className='inline-share-buttons' url={typeof (window) !== 'undefined' ? window.location.href : this.props.canonical} title={headline} quote={headline} media={resizeURL + calcImageSrcUrl(article.getImageObject(), 960, 'original')} />
                {readNext.contentKey &&
                  <article className='read-next'>
                    <Link to={'/' + readNext.getCanonicalUri()}>
                      <strong>Read Next:</strong><h4>{readNext.headline}</h4>
                    </Link>
                  </article>
                }
              </article>
              <Sidebar section={article.section}>
                <Klangoo widgetId={process.env.RAZZLE_KLANGOO_ID} article={article} klangooId={process.env.RAZZLE_KLANGOO_IDENTIFIER} />
              </Sidebar>
            </div>
          </div>
          <Ad isConnected={this.props.isConnected} path={`/${process.env.RAZZLE_DFP_CODE}/${canonicalUri}`} slotId='leaderboard-bot' targeting={{ leaderboard: 'bot' }} collapseEmptyDiv className='advert-leaderboard-bot' {...FORMATS.leaderboard} />
        </div>
      </Fragment>)
    } else {
      return (<Fragment>
        <Helmet title='Article' />
        <div className='main-article'>
          <Ad isConnected={this.props.isConnected} path={`/${process.env.RAZZLE_DFP_CODE}/${this.props.location.pathname}`} slotId='leaderboard-top' targeting={{ leaderboard: 'top' }} collapseEmptyDiv className='advert-leaderboard-top' {...FORMATS.leaderboard} />
          <div className='wrapper'>
            <div className='article-page'>
              <Segment>
                <Dimmer active>
                  <Loader indeterminate size='large' inline='centered'>Loading Article</Loader>
                </Dimmer>
              </Segment>
              <Sidebar section={articleUrl2section(this.props.location.pathname)} />
            </div>
          </div>
          <Ad isConnected={this.props.isConnected} path={`/${process.env.RAZZLE_DFP_CODE}/${this.props.location.pathname}`} slotId='leaderboard-bot' targeting={{ leaderboard: 'bot' }} collapseEmptyDiv className='advert-leaderboard-bot' {...FORMATS.leaderboard} />
        </div>
      </Fragment>)
    }
  }
}

const mapStateToProps = (state) => (state.article)
const mapDispatchToProps = (dispatch) => bindActionCreators({
  fetchArticle,
  setPreviewArticle
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Article)
