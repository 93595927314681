import React from 'react'
import { Link } from 'react-router-dom'
import { squareImage } from 'ion-article'
import AliceCarousel from 'react-alice-carousel'

const RelatedArticles = ({ relatedArticles, unknownImage }) => (
  <AliceCarousel
    buttonsDisabled
    swipeDisabled={false}
    keysControlDisabled={false}
    responsive={{
      0: {
        items: 1
      },
      600: {
        items: 2
      }
    }}>
    {relatedArticles.map(relatedArticle => (
      <article key={relatedArticle.id}>
        <Link to={relatedArticle.externalUrl}>
          <h3>{relatedArticle.title}</h3>
          <img src={squareImage(125, relatedArticle.image, unknownImage)} alt={relatedArticle.title} />
        </Link>
      </article>
    ))}
  </AliceCarousel>
)

export default RelatedArticles
