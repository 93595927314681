import React, { Fragment } from 'react'
import Ad from 'ion-ads'
import FORMATS from '../../components/AdFormats'
import ArticleCard from '../../components/ArticleCard'
import LazyLoad from 'react-lazyload'

const Default = ({ articles, authorName, section, noImage, isConnected, sectionLabel, moreButton }) => {
  return (
    <Fragment>
      <div className='wrapper'>
        <h1>{sectionLabel}</h1>
      </div>
      {articles.map((article, index) => {
        return (
          <Fragment key={article.contentKey}>
            {index <= 2 && (
              <ArticleCard article={article} noImage={noImage} />
            )}
            {index > 2 && (
              <LazyLoad height={320}>
                <ArticleCard article={article} noImage={noImage} />
              </LazyLoad>
            )}
            {(Number.isInteger((index - 26) / 18)) && (
              <Ad isConnected={isConnected} path={`/${process.env.RAZZLE_DFP_CODE}/${section}`} targeting={{ leaderboard: 'top2' }} collapseEmptyDiv className='advert-leaderboard-top' {...FORMATS.leaderboard} />
            )}
          </Fragment>
        )
      })}
      {moreButton}
    </Fragment>)
}

export default Default
